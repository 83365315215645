import Script from "next/script";

const UnamiAnalytics = () => {
  return (
    <>
      <Script
        defer
        src="https://cloud.umami.is/script.js"
        data-website-id="d035df33-a2b1-4fac-b545-b6afb955fc9c"
      />
    </>
  );
};

export default UnamiAnalytics;
